import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex-row-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_product_widget = _resolveComponent("product-widget")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_product_widget, {
      image: _ctx.page.banner,
      title: _ctx.page.title,
      subtitle: _ctx.page.shortDescription,
      description: _ctx.page.description
    }, null, 8, ["image", "title", "subtitle", "description"])
  ]))
}