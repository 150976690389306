
import { defineComponent } from "vue";
import ProductWidget from "@/view/content/widgets/engage/Widget3.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import productData from "@/data/product-collection.json";

import { API, Storage, graphqlOperation } from "aws-amplify";
import { listPageFeatureTables } from "@/graphql/queries";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "ai-tools",
  components: {
    ProductWidget,
  },
  setup() {
    const router = useRouter();
    const product = productData.featureCollection[0];

    return {
      product,
    };
  },
  mounted() {
    this.loadPage(
      "caldeiracloud_" + this.$route.params.slugname + "_pagefeature"
    );
  },
  data() {
    return {
      page: {
        title: "",
        banner: "",
        bannerSmall: "",
        metadata: {},
      },
    };
  },
  methods: {
    async loadPage(pageFeatureFeedId) {
      try {
        const response: any = await API.graphql(
          graphqlOperation(listPageFeatureTables, {
            filter: {
              pageId: {
                eq: pageFeatureFeedId,
              },
              enabled: {
                eq: true,
              },
            },
          })
        );

        if (response.data.listPageFeatureTables.items.length > 0) {
          this.page = response.data.listPageFeatureTables.items[0];
          if (this.page) {
            setCurrentPageBreadcrumbs("Caldeira Cloud", this.page.title);
            let metadata = JSON.parse(
              response.data.listPageFeatureTables.items[0].metadata
            );
            this.page.metadata = metadata;

            const banner = await Storage.get("ai-tools/" + this.page.banner, {
              level: "public",
            });
            this.page.banner = banner;

            const bannerSmall = await Storage.get(
              "ai-tools/" + this.page.bannerSmall,
              {
                level: "public",
              }
            );
            this.page.bannerSmall = bannerSmall;
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
});
